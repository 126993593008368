import { AuthService } from '@/services/auth.service';

export function checkAuthOrRedirect() {
  const isRouteRequiresAuth = window.location.pathname.startsWith('/app');
  const isRouteRequiresGuest = window.location.pathname.startsWith('/auth');

  const isUserAuthenticated = AuthService.isAuthenticated();

  if (isRouteRequiresAuth) {
    if (!isUserAuthenticated) {
      const referer = encodeURIComponent(window.location.href);
      window.location.replace(`/auth/login?referer=${referer}`);
      return null;
    }
  }
  if (isRouteRequiresGuest) {
    if (isUserAuthenticated) {
      window.location.replace('/app');
      return null;
    }
  }
}

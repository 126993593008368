import Vue from 'vue';
import store from '@/store';
import Base from './Base.vue';
import router from './router/router';

Vue.config.productionTip = false;

/* eslint-disable no-new, vue/order-in-components */

import './new/styles/styles.scss';
import NavigatorHelper from 'utils/NavigatorHelper';
import { AuthService } from '@/services/auth.service';
import { persistentStorage, PersistentStorageKeys } from 'utils/persistentStorage';

// Init scripts
import '@/services/FaviconService';

const lastLocation = persistentStorage.get(PersistentStorageKeys.LAST_LOCATION);
if (NavigatorHelper.isStandaloneApplication && AuthService.isAuthenticated() && lastLocation) {
  window.location.href = lastLocation;
} else {
  new Vue({
    render: (h) => h(Base),
    mounted: () => document.dispatchEvent(new Event('render-event')),
    router,
    store,
  }).$mount('#app');
}

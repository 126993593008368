import { Dict } from '@/types/utility';

type BaseError = {
  message: string;
  fields: Dict<string>;
};

export default class GraphqlResponseError extends Error {
  constructor(message = `Can't get data from a graphql server!`, public params?: BaseError) {
    super(message);
    this.name = 'GraphqlResponseError';
  }
}

import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/prerender-pages/router/routes';

Vue.use(VueRouter);

const Router = new VueRouter({
  routes,
  mode: `history`,
  base: `${process.env.BASE_URL}`,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

export default Router;

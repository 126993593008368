import RequestError from '@/errors/RequestError';

export class PostprocessingTemplatesApi {
  static async getDefaultValues() {
    const url = `https://get.chrom.app/postprocessing-template_default_values.json`;
    const response = await fetch(url, {
      method: 'get',
      headers: {
        'Content-Type': 'text/plain',
      },
    });

    if (response.ok) {
      return response.json();
    }

    throw new RequestError();
  }
}

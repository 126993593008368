import { Nullable } from '@/types/utility';
import { envVariables } from '@/utils/envHelpers';
import { compareVersions, validate } from 'compare-versions';

type Version = string | number[];

export default class VersionHelper {
  public static get clientVersion() {
    return envVariables.APP_VERSION;
  }

  /***
   * To compare two versions in format "1.20.3" or [1, 20, 3] or commit hash like "dev-12345678".
   *
   * Result will be true if commit hashes are different.
   */
  static greaterThan(a: Nullable<Version>, b: Nullable<Version>): boolean {
    if (!a || !a.length || !b || !b.length) {
      return false;
    }

    const isCommitHashA = typeof a === 'string' && a.length >= 12 && /^dev-\w{8}$/.test(a);
    const isCommitHashB = typeof b === 'string' && b.length >= 12 && /^dev-\w{8}$/.test(b);
    if (isCommitHashA || isCommitHashB) {
      return a !== b;
    }

    const aParsed = typeof a === 'string' ? a : a.join('.');
    const bParsed = typeof b === 'string' ? b : b.join('.');

    if (validate(aParsed) && validate(bParsed)) {
      return compareVersions(aParsed, bParsed) === 1;
    }

    throw new Error('Version is invalid! Should be a valid semver version!');
  }
}

export const POSTPROCESSING_TEMPLATE_EMPTY = {
  id: null,
  name: '',
  is_archived: false,
  is_favorite: false,
  detection_time_start_sec: null,
  detection_time_end_sec: null,
  algorithm_base: null,
  baseline_correction_anchor_point: null,
  algorithm_binj: null,
  blank_injection_measurement_ulid: null,
  blank_injection_measurement_id: null,
  algorithm_pdet: null,
  peak_detection_min_distance: null,
  peak_detection_min_height: null,
  peak_detection_min_width: null,
  peak_detection_min_prominence: null,
  algorithm_peak: null,
  peak_integration_quality_threshold: null,
  algorithm_nois: null,
  baseline_correction_points: null,

  defaultValues: {},
};

export const POSTPROCESSING_TEMPLATE_DEFAULT = {
  ...POSTPROCESSING_TEMPLATE_EMPTY,
};

export const POSTPROCESSING_TEMPLATE_ERRORS_DEFAULT = {
  ...POSTPROCESSING_TEMPLATE_EMPTY,
};

export const POSTPROCESSING_TEMPLATE_DEFAULT_NAME_NEW = 'New Postprocessing Template';

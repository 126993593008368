import AuthAPI from '@/api/auth';
import { persistentStorage, PersistentStorageKeys } from '@/utils/persistentStorage';
import { parseJwt } from '@/utils/jwt';
import _ from 'lodash';
import { Nullable } from '@/types/utility';
import { apiHasura } from '@/api/hasura';

const MS_IN_SECOND = 1000;
const FIVE_SECONDS = 5 * MS_IN_SECOND;

function isTokenOld(token: string, expiry: number): boolean {
  if (token && expiry) {
    const now = new Date();
    return expiry - FIVE_SECONDS < now.getTime();
  }
  return true;
}

const authServiceListeners: ((token: string, data: any) => void)[] = [];

export class AuthService {
  public static requestRefreshToken: Nullable<Promise<any>>;

  public static login(email, pass, onSuccess, onError) {
    return AuthAPI.login(
      email,
      pass,
      (data) => {
        this.saveRefreshToken(data.refresh);
        this.saveToken(data.access);
        this.saveExpiry(new Date().getTime() + data.expires_in * MS_IN_SECOND);
        onSuccess(data);
      },
      onError,
    );
  }

  public static googleLogin(idToken, onSuccess, onError) {
    return AuthAPI.googleLogin(
      idToken,
      (data) => {
        this.saveRefreshToken(data.refresh);
        this.saveToken(data.access);
        this.saveExpiry(new Date().getTime() + data.expires_in * MS_IN_SECOND);
        onSuccess(data);
      },
      onError,
    );
  }

  public static isAuthenticated() {
    return this.getToken() != null;
  }

  public static userData() {
    return parseJwt(this.getToken()) ?? {};
  }

  public static token() {
    return this.getToken();
  }

  public static tokenRefreshedIfOld() {
    const token = this.getToken();

    if (token && this.expiry) {
      return isTokenOld(token, this.expiry) ? this.refreshAccessToken() : token;
    }

    return null;
  }

  public static async refreshAccessToken() {
    AuthService.requestRefreshToken =
      AuthService.requestRefreshToken ?? AuthAPI.refreshToken(this.getRefreshToken(), null, null);

    try {
      const { ok, data } = (await AuthService.requestRefreshToken) || {};
      if (ok && data.access) {
        const { access, refresh, expires_in } = data;
        refresh && this.saveRefreshToken(refresh);
        this.saveToken(access);
        this.saveExpiry(new Date().getTime() + expires_in * MS_IN_SECOND);

        return access;
      }
      this.saveToken(undefined);

      return null;
    } finally {
      AuthService.requestRefreshToken = null;
    }
  }

  public static async logout() {
    await AuthAPI.logout(null);
    this.saveRefreshToken(null);
    this.saveToken(null);
    this.saveExpiry(null);
  }

  public static addListener(l) {
    authServiceListeners.push(l);
  }

  public static removeListener(l) {
    _.pull(authServiceListeners, l);
  }

  // Private

  private static getToken() {
    return persistentStorage.get(PersistentStorageKeys.ACCESS_TOKEN_KEY, {
      isForActiveUser: false,
    });
  }

  private static saveToken(token) {
    persistentStorage.set(PersistentStorageKeys.ACCESS_TOKEN_KEY, token, {
      isForActiveUser: false,
    });
    this.broadcast(token);
  }

  private static getRefreshToken() {
    return persistentStorage.get(PersistentStorageKeys.REFRESH_TOKEN_KEY, {
      isForActiveUser: false,
    });
  }

  private static saveRefreshToken(token) {
    persistentStorage.set(PersistentStorageKeys.REFRESH_TOKEN_KEY, token, {
      isForActiveUser: false,
    });
  }

  private static saveExpiry(expiry) {
    persistentStorage.set(PersistentStorageKeys.EXPIRY, expiry, { isForActiveUser: false });
  }

  private static get expiry() {
    return persistentStorage.get(PersistentStorageKeys.EXPIRY, { isForActiveUser: false });
  }

  private static broadcast(token) {
    authServiceListeners.forEach((l) => l(token, parseJwt(token)));
  }

  public static async getHasuraAnonymousToken(shareToken, objectId) {
    const token = persistentStorage.get(PersistentStorageKeys.ANONYMOUS_TOKEN, {
      isForActiveUser: false,
    });

    if (token) {
      const data = parseJwt(token);

      if (!isTokenOld(token, data.exp * MS_IN_SECOND)) {
        return token;
      }
    }

    const newToken = await apiHasura.getAnonymousToken(shareToken, objectId);

    persistentStorage.set(PersistentStorageKeys.ANONYMOUS_TOKEN, newToken, {
      isForActiveUser: false,
    });

    return newToken;
  }
}

import { request, RequestDocument } from 'graphql-request';
import GraphqlResponseError from '@/errors/GraphqlResponseError';
import { AuthService } from '@/services/auth.service';
import HasuraAuthenticationError from '@/errors/HasuraAuthenticationError';
import { Dict } from '@/types/utility';
import { envVariables } from '@/utils/envHelpers';
import JwtExpiredError from '@/errors/JwtExpiredError';

const makeGraphqlRequestBase = (url: string, requestHeaders: HeadersInit) => {
  return async (query: RequestDocument, variables: Dict<unknown>): Promise<unknown> => {
    try {
      return await request({
        url,
        document: query,
        variables,
        requestHeaders,
      });
    } catch (e: any) {
      if (e.response?.errors?.length) {
        if (e.response.errors[0].extensions?.code === 'invalid-jwt') {
          throw new JwtExpiredError();
        }

        const payload = JSON.parse(e.response.errors[0].message);

        throw new GraphqlResponseError(payload.message, payload);
      }

      throw e;
    }
  };
};

export const makeGraphqlRequestCatalog = (
  query: RequestDocument,
  variables: Dict<unknown>,
): Promise<unknown> => {
  if (envVariables.URL_GRAPHQL_CATALOG) {
    const catalogToken = AuthService.userData().hasura_token;

    if (!catalogToken) {
      throw new HasuraAuthenticationError('Hasura token is not provided');
    }

    return makeGraphqlRequestBase(envVariables.URL_GRAPHQL_CATALOG, {
      'x-hasura-admin-secret': catalogToken,
    })(query, variables);
  }
  throw new Error('Url must be provided');
};

export const makeGraphqlRequestCloud = async (
  query: RequestDocument,
  variables: Dict<unknown>,
  attempt = 1,
  shareToken?: string,
  sharedObjectId?: number,
): Promise<unknown> => {
  const token = shareToken
    ? await AuthService.getHasuraAnonymousToken(shareToken, String(sharedObjectId))
    : await AuthService.tokenRefreshedIfOld();
  try {
    return await makeGraphqlRequestBase('/hasura/v1/graphql', {
      Authorization: `Bearer ${token}`,
    })(query, variables);
  } catch (e) {
    if (e instanceof JwtExpiredError && attempt < 2) {
      return makeGraphqlRequestCloud(query, variables, attempt + 1, shareToken, sharedObjectId);
    }
    throw e;
  }
};

import { REST } from 'utils/rest';

export const URL_TOKEN = '/api/token/';
export const URL_TOKEN_REFRESH = '/api/token/refresh/';

class AuthAPI {
  static login(username, password, onSuccess, onError) {
    return REST.post(URL_TOKEN, { username, password }, onSuccess, onError);
  }

  static refreshToken(refreshToken, onSuccess, onError) {
    return REST.post(URL_TOKEN_REFRESH, { refresh: refreshToken }, onSuccess, onError, {
      hasAuthHeaders: false,
    });
  }

  static logout(onSuccess) {
    if (onSuccess) onSuccess();
  }

  static signup(email, password, onSuccess, onError) {
    const url = '/api/accounts/';
    const data = { email, password };
    return REST.post(url, data, onSuccess, onError);
  }

  static googleLoginClientId(onSuccess, onError) {
    const url = '/api/google_signin/client_id/';
    return REST.get(url, onSuccess, onError);
  }

  static googleLogin(idToken, onSuccess, onError) {
    const url = '/api/accounts/:google_signin/';
    return REST.post(url, { id_token: idToken }, onSuccess, onError);
  }

  static sendPasswordRecoveryLetter(email, onSuccess, onError) {
    const url = '/api/accounts/:recover-password/';
    const data = { email };
    return REST.post(url, data, onSuccess, onError);
  }

  static resetPasswordByCode(code, password, onSuccess, onError) {
    const url = '/api/accounts/:reset-password/';
    const data = { code, password };
    return REST.post(url, data, onSuccess, onError);
  }

  static checkPasswordResetCode(code, onSuccess, onError) {
    const url = `/api/accounts/:check-reset-password/?code=${code}`;

    return REST.get(url, onSuccess, onError);
  }
}

export default AuthAPI;

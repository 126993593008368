import NavigatorHelper from '@/utils/NavigatorHelper';
import { isBrowserTabActive } from '@/utils/browserHelpers';

export enum FaviconStatuses {
  NONE = 'none',
  RUNNING = 'running',
  ERROR = 'error',
  COMPLETED = 'completed',
}

const BLINKING_INTERVAL_MS = 700;

class FaviconService {
  private isDarkMode: boolean = false;
  private status: FaviconStatuses = FaviconStatuses.NONE;
  private intervalId?: number;

  constructor() {
    this.initDarkModeDetection();
    this.initTabVisibilityWatching();
  }

  private initDarkModeDetection() {
    const mediaQueryDarkMode = window.matchMedia('(prefers-color-scheme: dark)');

    if (mediaQueryDarkMode.matches) {
      this.isDarkMode = true;
      this.refreshFavicon();
    }

    mediaQueryDarkMode.addEventListener('change', (e) => {
      this.isDarkMode = e.matches;
      this.refreshFavicon();
    });
  }

  private initTabVisibilityWatching() {
    document.addEventListener('visibilitychange', () => {
      if (
        document.visibilityState === 'visible' &&
        (this.status === FaviconStatuses.ERROR || this.status === FaviconStatuses.COMPLETED)
      ) {
        this.setStatus(FaviconStatuses.NONE);
      }
    });
  }

  private refreshFavicon() {
    clearInterval(this.intervalId);

    if (NavigatorHelper.isMobile) {
      return;
    }

    const links: NodeListOf<HTMLLinkElement> = document.querySelectorAll("link[rel$='icon']");

    links.forEach((linkElement) => {
      linkElement.href = this.getPathToFavicon(linkElement);
    });

    // Start blinking
    if (
      this.status === FaviconStatuses.RUNNING ||
      (this.status === FaviconStatuses.ERROR && !isBrowserTabActive())
    ) {
      let isVisible = true;
      this.intervalId = setInterval(() => {
        links.forEach((linkElement) => {
          linkElement.href = this.getPathToFavicon(
            linkElement,
            isVisible ? FaviconStatuses.NONE : this.status,
          );
        });

        isVisible = !isVisible;
      }, BLINKING_INTERVAL_MS);
    }
  }

  private getPathToFavicon(link: HTMLLinkElement, status: FaviconStatuses = this.status): string {
    return link.href.replace(
      /\/(?!.*\/)([^\--]+)--.+?(\.\w+)$/,
      `/$1${this.isDarkMode ? '--dark' : ''}--${status}$2`,
    );
  }

  public setStatus(status: FaviconStatuses) {
    this.status =
      status === FaviconStatuses.RUNNING || !isBrowserTabActive() ? status : FaviconStatuses.NONE;

    this.refreshFavicon();
  }
}

const faviconService = new FaviconService();
export default faviconService;

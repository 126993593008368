import { makeGraphqlRequestCloud } from 'api/graphql/graphQLClient';
import { Methods } from '@/graphql/cloud/methods/queries/Methods.graphql';
import { MethodsByIds } from '@/graphql/cloud/methods/queries/MethodsByIds.graphql';
import { Method } from '@/graphql/cloud/methods/queries/Method.graphql';
import { SetFavoriteStatus } from '@/graphql/cloud/methods/mutations/SetFavoriteStatus.graphql';
import { SetArchivingStatus } from '@/graphql/cloud/methods/mutations/SetArchivingStatus.graphql';

export const apiMethods = {
  async getMethod(methodId) {
    const { method } = await makeGraphqlRequestCloud(Method, {
      methodId,
    });

    return method;
  },
  async getMethods({
    search = '',
    isArchived = false,
    offset = 0,
    limit,
    hasOnlyFavorites,
    hasSortingByFavorites,
    methodIdsToExclude,
    hardwareConfiguration,
  }) {
    const id = Number(search);
    const _ilike = `%${search ?? ''}%`;
    const searchById = Number.isInteger(id) && id > 0 ? id : undefined;
    const whereSearchByNameOrId = [{ name: { _ilike } }];
    if (searchById) {
      whereSearchByNameOrId.push({ id: { _eq: searchById } });
    }

    const { methods, methods_aggregate } = await makeGraphqlRequestCloud(Methods, {
      whereSearchByNameOrId,
      offset,
      limit,
      hasOnlyFavorites: hasOnlyFavorites ? true : null,
      isArchived,
      hasSortingByFavorites,
      methodIdsToExclude,
      hardwareConfiguration,
    });

    return {
      methods,
      count: methods_aggregate.aggregate.count,
    };
  },
  async getMethodsByIds(methodIds) {
    const { methods } = await makeGraphqlRequestCloud(MethodsByIds, { methodIds });

    return {
      methods,
    };
  },
  addToFavorites(methodId) {
    return makeGraphqlRequestCloud(SetFavoriteStatus, {
      methodId,
      isFavorite: true,
    });
  },
  removeFromFavorites(methodId) {
    return makeGraphqlRequestCloud(SetFavoriteStatus, {
      methodId,
      isFavorite: false,
    });
  },
  archive(methodId) {
    return makeGraphqlRequestCloud(SetArchivingStatus, {
      methodId,
      isArchived: true,
    });
  },
  restore(methodId) {
    return makeGraphqlRequestCloud(SetArchivingStatus, {
      methodId,
      isArchived: false,
    });
  },
};

/* eslint-disable no-console */
// import logService, { LogLevels } from '@/services/LogService';
// import { stringifyCircularStructure } from '@/utils/jsonHelpers';

// const stringifyMessages = (messages: string[]) =>
//   messages
//     .map((message) => (typeof message === 'object' ? stringifyCircularStructure(message) : message))
//     .join(' ');

/**
 * Use these helpers to send messages to server
 */
export const consoleHelpers = {
  log(...messages) {
    console.log(...messages);
    // logService.log(stringifyMessages(messages), LogLevels.INFORMATION);
  },
  warn(...messages) {
    console.warn(...messages);
    // logService.log(stringifyMessages(messages), LogLevels.INFORMATION);
  },
  error(...messages) {
    console.error(...messages);
    // logService.log(stringifyMessages(messages), LogLevels.ERROR);
  },
};

const LayoutPublic = () => import('@/prerender-pages/new/layout/LayoutPublic');
const Home = () => import('@/prerender-pages/new/pages/Home');
const Contacts = () => import('@/prerender-pages/new/pages/Contacts');
const StubRedirect = () => import('@/prerender-pages/new/pages/StubRedirect');

const createLinkToLanding = (path) => `https://newcrom.com/${path}`;

const routesForRedirecting = [
  {
    path: '/hardware',
    meta: {
      redirect: createLinkToLanding('shop'),
    },
    component: StubRedirect,
  },
  {
    path: '/try',
    meta: {
      redirect: createLinkToLanding('try'),
    },
    component: StubRedirect,
  },
  {
    path: '/newcrom',
    meta: {
      redirect: createLinkToLanding('newcrom'),
    },
    component: StubRedirect,
  },
  {
    path: '/autosampler',
    meta: {
      redirect: createLinkToLanding('shop#autosampler'),
    },
    component: StubRedirect,
  },
  {
    path: '/pump',
    meta: {
      redirect: createLinkToLanding('shop#pump'),
    },
    component: StubRedirect,
  },
  {
    path: '/detector',
    meta: {
      redirect: createLinkToLanding('shop#detector'),
    },
    component: StubRedirect,
  },
  {
    path: '/valve',
    meta: {
      redirect: createLinkToLanding('shop#valve'),
    },
    component: StubRedirect,
  },
  {
    path: '/streamlc',
    meta: {
      redirect: createLinkToLanding('shop'),
    },
    component: StubRedirect,
  },
];

const routesForPrerendering = [
  {
    path: '/',
    component: Home,
    name: 'home',
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: Contacts,
  },
  ...routesForRedirecting,
];

const routes = [
  {
    path: '',
    component: LayoutPublic,
    children: routesForPrerendering,
  },
  // Not found
  {
    path: '*',
    name: 'NotFound',
  },
];

module.exports = routes;
module.exports.routesForPrerendering = routesForPrerendering;

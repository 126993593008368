import { Nullable } from '@/types/utility';
import { AuthService } from '@/services/auth.service';

export enum PersistentStorageKeys {
  DEVICE_VERSION_UPDATE_LATER = 'DEVICE_VERSION_UPDATE_LATER',
  LAST_LOCATION = 'LAST_LOCATION',

  ACCESS_TOKEN_KEY = 'hplc-access-token',
  REFRESH_TOKEN_KEY = 'hplc-refresh-token',
  EXPIRY = 'hplc-expiry-time',

  ANONYMOUS_TOKEN = 'hplc-anonymous-token',

  CHROMATOGRAMS_COMPARE_IDS = 'chromatograms-compare-ids',
  IS_DUAL_MODE = 'isAllowDualMode',
  LAST_USED_APP_VERSION = 'lastUsedAppVersion',
  ACTIVE_CHROMATOGRAM_ID = 'ACTIVE_CHROMATOGRAM_ID',
  WAS_SUGGESTED_PWA_INSTALLATION = 'wasSuggestedPWAInstallation',
  REPORT_DATA = 'reportData',
  DEVICES_ORDER = 'devicesOrder',

  CHROMATOGRAM_APPEARANCE = 'chromatogramAppearance',
}

type StorageItems = {
  [PersistentStorageKeys.DEVICE_VERSION_UPDATE_LATER]: { version: string; timestamp: number };
  [PersistentStorageKeys.LAST_LOCATION]: string;
  [PersistentStorageKeys.CHROMATOGRAMS_COMPARE_IDS]: number[];
  [PersistentStorageKeys.IS_DUAL_MODE]: boolean;
  [PersistentStorageKeys.LAST_USED_APP_VERSION]: string;
  [PersistentStorageKeys.ACTIVE_CHROMATOGRAM_ID]: number;
  [PersistentStorageKeys.WAS_SUGGESTED_PWA_INSTALLATION]: boolean;
  [PersistentStorageKeys.REPORT_DATA]: object;
  [PersistentStorageKeys.DEVICES_ORDER]: number[];

  [PersistentStorageKeys.ACCESS_TOKEN_KEY]: string;
  [PersistentStorageKeys.REFRESH_TOKEN_KEY]: string;
  [PersistentStorageKeys.EXPIRY]: number;

  [PersistentStorageKeys.ANONYMOUS_TOKEN]: string;

  [PersistentStorageKeys.CHROMATOGRAM_APPEARANCE]: {
    hasGridLines: boolean;
    hasTooltipOnHover: boolean;
  };
};

export const persistentStorage = {
  get userId(): Nullable<number> {
    return AuthService.userData().user_id ?? 'guest';
  },

  set<T extends PersistentStorageKeys>(
    key: T,
    value: StorageItems[T],
    { isForActiveUser = true, params = [] } = {},
  ) {
    const keyTrailingPart = params.length ? `:${params.join(':')}` : '';
    const _key = isForActiveUser
      ? `${key}-${this.userId}${keyTrailingPart}`
      : `${key}${keyTrailingPart}`;

    // eslint-disable-next-line eqeqeq
    if (value == undefined) {
      return localStorage.removeItem(_key);
    }

    return localStorage.setItem(_key, JSON.stringify(value));
  },

  get<T extends PersistentStorageKeys>(
    key: T,
    { isForActiveUser = true, isJSON = true, params = [] } = {},
  ): Nullable<StorageItems[T]> {
    const keyTrailingPart = params.length ? `:${params.join(':')}` : '';
    const _key = isForActiveUser
      ? `${key}-${this.userId}${keyTrailingPart}`
      : `${key}${keyTrailingPart}`;

    const data = localStorage.getItem(_key) as any;

    // eslint-disable-next-line eqeqeq
    if (data != undefined && isJSON) {
      try {
        return JSON.parse(data);
      } catch (e) {
        return data;
      }
    }

    return data;
  },

  remove(key: PersistentStorageKeys, { isForActiveUser = true, params = [] } = {}) {
    const keyTrailingPart = params.length ? `:${params.join(':')}` : '';
    const _key = isForActiveUser
      ? `${key}-${this.userId}${keyTrailingPart}`
      : `${key}${keyTrailingPart}`;
    localStorage.removeItem(_key);
  },
};

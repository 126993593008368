import { AuthService } from '@/services/auth.service';
import { checkAuthOrRedirect } from '@/utils/url-protect';
import { fetchExtended, Url } from '@/utils/fetch-extended';
import { getCookie } from '@/utils/utils';
import { consoleHelpers } from '@/utils/logHelpers';
import VersionHelper from '@/utils/VersionHelper';
import { store } from '@/store';

type Options = {
  hasAuthHeaders: boolean;
};

async function createAuthHeader(options: Options) {
  if (options.hasAuthHeaders) {
    const token = await AuthService.tokenRefreshedIfOld();
    if (!token) return undefined;
    return { Authorization: `Bearer ${token}` };
  }
}

function createCsrfHeader() {
  return { 'X-CSRFToken': getCookie('csrftoken') };
}

async function expiredAccessTokenInterceptor(
  response: Response,
  url,
  options,
): Promise<Response | undefined> {
  const _response = response.clone();

  // eslint-disable-next-line eqeqeq
  if (url == '/api/token/refresh/') return undefined;

  if (_response.status === 401) {
    const data = await _response.json();
    if (
      data.code === 'token_not_valid' ||
      data.detail === 'Authentication credentials were not provided.'
    ) {
      const token = await AuthService.refreshAccessToken();
      if (token) {
        delete options.signal;
        options.headers.set('Authorization', `Bearer ${AuthService.token()}`);
        return fetchExtended(url, options);
      }
      checkAuthOrRedirect();
    }
  }
  return undefined;
}

const checkServerVersion = (response) => {
  const serverVersion = response.headers.get('version');

  if (VersionHelper.greaterThan(serverVersion, VersionHelper.clientVersion)) {
    store.commit('setIsServerVersionGreater', true);
  }
};

const headerProviders: Array<(options: Options) => any> = [createCsrfHeader, createAuthHeader];
const responsePreprocessors: Array<(Response) => any> = [checkServerVersion];
const responseInterceptor = expiredAccessTokenInterceptor;

export class REST {
  private static isStatusOk(statusCode) {
    return statusCode >= 200 && statusCode < 300;
  }

  private static async createHeaders(options: Options): Promise<any> {
    let headers = {};
    for (const headerProvider of headerProviders) {
      const header = await headerProvider(options);
      headers = { ...header, ...headers };
    }
    return headers;
  }

  private static async createFetch(
    method: string,
    url: Url = '/',
    body: any = undefined,
    contentType: string | undefined = undefined,
    _options: Options = { hasAuthHeaders: true },
  ) {
    const contentTypeHeader = contentType ? { 'Content-Type': contentType } : undefined;
    const headers = await REST.createHeaders(_options);

    const options = {
      credentials: 'same-origin', // Important, helps to set and send cookies via AJAX
      method,
      headers: new Headers({ ...contentTypeHeader, ...headers }),
      body,
      redirect: 'follow',
    };

    return fetchExtended(url, options, responseInterceptor);
  }

  private static createFetchJSON(
    method: string,
    url: Url = '/',
    data: any = undefined,
    options: Options = { hasAuthHeaders: true },
  ) {
    return REST.createFetch(method, url, data && JSON.stringify(data), 'application/json', options);
  }

  private static preprocessResponse(response) {
    responsePreprocessors.forEach((preprocessor) => preprocessor(response));
  }

  private static async doRequest(
    fetch,
    onSuccess: (json: any, status: number) => void,
    onError?: (json: any, status: number) => void,
  ) {
    let response;
    try {
      response = await fetch;
    } catch (ex: any) {
      consoleHelpers.warn('Exception on fetch()', ex);
      return onError?.({ msg: ex?.toString() }, -1);
    }

    await REST.preprocessResponse(response);

    const _response = response.clone();
    // The request must be cloned to parse it a second time as plain text, if the received data is not valid json
    const _responseClonedToRepeatParsing = response.clone();

    let data;
    try {
      data = await _response.json();
    } catch (ex) {
      consoleHelpers.warn('Unable to decode body json', ex);

      if (_response.status >= 500) {
        data = _response.statusText;
      } else {
        try {
          data = await _responseClonedToRepeatParsing.text();
        } catch (e) {
          data = 'Invalid data';
        }
      }
    }

    const { status } = _response;
    const ok = REST.isStatusOk(status);
    if (ok) {
      if (onSuccess != null) onSuccess(data, status);
    } else {
      if (onError != null) onError(data, status);
    }

    return { data, status, response, ok };
  }

  static get(
    url: Url,
    onSuccess: (json: any, status: number) => void,
    onError: (json: any, status: number) => void,
  ) {
    return REST.doRequest(REST.createFetchJSON('GET', url), onSuccess, onError);
  }

  static post(
    url: Url,
    data,
    onSuccess: (json: any, status: number) => void,
    onError: (json: any, status: number) => void,
    options: Options = { hasAuthHeaders: true },
  ) {
    return REST.doRequest(REST.createFetchJSON('POST', url, data, options), onSuccess, onError);
  }

  static postMultipart(
    url: Url,
    body,
    onSuccess: (json: any, status: number) => void,
    onError: (json: any, status: number) => void,
  ) {
    return REST.doRequest(REST.createFetch('POST', url, body), onSuccess, onError);
  }

  static put(
    url: Url,
    data,
    onSuccess: (json: any, status: number) => void,
    onError: (json: any, status: number) => void,
  ) {
    return REST.doRequest(REST.createFetchJSON('PUT', url, data), onSuccess, onError);
  }

  static patch(
    url: Url,
    data,
    onSuccess: (json: any, status: number) => void,
    onError: (json: any, status: number) => void,
  ) {
    return REST.doRequest(REST.createFetchJSON('PATCH', url, data), onSuccess, onError);
  }

  static delete(
    url: Url,
    onSuccess: (json: any, status: number) => void,
    onError: (json: any, status: number) => void,
  ) {
    return REST.doRequest(REST.createFetchJSON('DELETE', url), onSuccess, onError);
  }
}

import { ERRORS } from '../constants/errors/errors';

export const getErrorMessage = (rawMessage?: string | number) =>
  rawMessage != null ? ERRORS[rawMessage] ?? rawMessage : rawMessage;

export const throwError = (error: string | Error): never => {
  if (typeof error === 'string') {
    throw new Error(error);
  }
  throw error;
};

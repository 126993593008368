import Vue from 'vue';
import Vuex from 'vuex';
import { apiMethods } from 'api/graphql/cloud/methods';
import hash from 'object-hash';
import { apiPostprocessingTemplates } from 'api/graphql/cloud/postprocessingTemplates';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    isServerVersionGreater: false,

    methods: {},
    postprocessingTemplates: {},
  },

  mutations: {
    setIsServerVersionGreater(state, value) {
      state.isServerVersionGreater = value;
    },

    setMethods(state, { hash, methodsPromise }) {
      state.methods = {
        ...state.methods,
        [hash]: methodsPromise,
      };
    },
    resetMethods(state) {
      state.methods = {};
    },

    setPostprocessingTemplates(state, { hash, postprocessingTemplatesPromise }) {
      state.postprocessingTemplates = {
        ...state.postprocessingTemplates,
        [hash]: postprocessingTemplatesPromise,
      };
    },
    resetPostprocessingTemplates(state) {
      state.postprocessingTemplates = {};
    },
  },

  actions: {
    initMethods({ commit }, variables) {
      const variablesHash = hash(variables);
      const methodsPromise = apiMethods.getMethods(variables);
      commit('setMethods', { hash: variablesHash, methodsPromise });
    },

    initPostprocessingTemplates({ commit }, variables) {
      const variablesHash = hash(variables);
      const postprocessingTemplatesPromise = apiPostprocessingTemplates.getPostprocessingTemplates(
        variables,
      );
      commit('setPostprocessingTemplates', { hash: variablesHash, postprocessingTemplatesPromise });
    },
  },
});

export default store;

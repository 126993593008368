import NavigatorHelper from '@/utils/NavigatorHelper';

export const openInNewTab = (url: string) => {
  // Open in current tab for PWA applications
  if (NavigatorHelper.isStandaloneApplication) {
    window.location.href = url;
    return;
  }

  window.open(url, '_blank')?.focus();
};

export const isBrowserTabActive = () => document.visibilityState === 'visible';

export const copyToClipboard = (value: string) => navigator.clipboard.writeText(value);

export function isPrevDateOlder(date, prev) {
  if (date == null) return false;
  if (prev == null) return true;
  return new Date(prev) < new Date(date);
}

export function getPathname(href) {
  if (href) {
    const url = new URL(href);
    return url.pathname + url.search;
  }
  return null;
}

export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`),
  );
  return matches ? decodeURIComponent(matches[1]) : '';
}

export function getClientVersion() {
  return window.ENV_APP_VERSION;
}

import * as Sentry from '@sentry/vue';

class CustomSentryError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'CustomSentryError';
  }
}

export enum Levels {
  DEBUG = 'debug',
  INFO = 'info',
  ERROR = 'info',
  WARNING = 'warning',
  FATAL = 'fatal',
}

export const sendEvent = (
  level: Levels,
  error: Error | string,
  data: [string, string | number | object][],
) => {
  Sentry.captureException(
    error instanceof Error ? error : new CustomSentryError(error),
    (scope) => {
      // Unable to import Severity enum from Sentry library
      // @ts-ignore
      scope.setLevel(level);

      data.forEach(([name, value]) => scope.setExtra(name, value));

      return scope;
    },
  );
};

export const sendDebugEvent = sendEvent.bind(null, Levels.DEBUG);
export const sendInfoEvent = sendEvent.bind(null, Levels.INFO);

export const isProduction = () => process.env.NODE_ENV === 'production';

const getVariable = (name: string): string =>
  isProduction() ? window[`ENV_${name}`] : process.env[name];

export const envVariables = {
  APP_MODE: getVariable('VUE_APP_MODE'),
  APP_VERSION: getVariable('APP_VERSION'),
  SENTRY_DSN: getVariable('VUE_APP_SENTRY_DSN'),
  OPENREPLAY_KEY: getVariable('VUE_APP_OPENREPLAY_KEY'),
  URL_GRAPHQL_CATALOG: getVariable('VUE_APP_URL_GRAPHQL_CATALOG'),
  URL_LOKI: getVariable('VUE_APP_URL_LOKI'),
  MAGIC_VALUES_ARE_NOT_SUPPORTED_AFTER_UNIXTIME_MS: getVariable(
    'VUE_APP_MAGIC_VALUES_ARE_NOT_SUPPORTED_AFTER_UNIXTIME_MS',
  ),
};

import { Dict } from '@/types/utility';
import { sendInfoEvent } from '@/utils/sentryHelpers';

const FETCH_TIMEOUT = 20000;
const TIMEOUT_RESPONSE = JSON.stringify({ detail: 'Timeout' });

export type Url =
  | string
  | {
      path: string;
      query?: Dict<string>;
    };

const parseUrl = (url: Url) => {
  if (typeof url === 'string') {
    return url;
  }

  const { path, query } = url;

  const queryParsed = query ? '?' + new URLSearchParams(query).toString() : '';

  return path + queryParsed;
};

// Fetch with interceptor and timeout
export const fetchExtended = (
  url: Url,
  options: any = {},
  interceptor:
    | undefined
    | ((Response, url: string, options: any) => Promise<Response | undefined>) = undefined,
  timeout = FETCH_TIMEOUT,
) => {
  if (options.signal) throw new Error('In fetchExtended() options.signal should not be defined');
  const controller = new AbortController();
  const { signal } = controller;
  // eslint-disable-next-line no-param-reassign
  options = { signal, ...options };

  return new Promise<Response>((resolve, reject) => {
    const timer = setTimeout(() => {
      resolve(new Response(TIMEOUT_RESPONSE, { status: 408 }));
      controller.abort();

      sendInfoEvent('Timeout', [
        ['Request url', url],
        ['Request options', options],
      ]);
    }, timeout);

    const parsedUrl = parseUrl(url);

    return fetch(parsedUrl, options)
      .finally(() => clearTimeout(timer))
      .then(async (response) => {
        clearTimeout(timer);
        const newResponse = interceptor && (await interceptor(response, parsedUrl, options));
        resolve(newResponse ?? response);
      })
      .catch(reject);
  });
};

import RequestError from '@/errors/RequestError';

const getAnonymousToken = async (shareToken, objectId) => {
  const url = `/api/hasura/auth/share-token`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      share_token: shareToken,
      id: objectId,
    }),
  });

  if (response.ok) {
    const data = await response.json();
    return data.token;
  }

  throw new RequestError();
};

export const apiHasura = {
  getAnonymousToken,
};
